/**
 * Pluralize a text by a given number
 *
 * pluralize(1, 'apple') => '1 apple'
 * pluralize(2, 'apple') => '2 apples'
 * pluralize(2, ['mouse', 'mice']) => '2 mice'
 * pluralize(2, 'apple', true) => 'apples'
 *
 * @param number number
 * @param original string|[string, string]  Singular text or an array contains [singular, plural]
 * @param hideNumber boolean? Whether to hide prefix number
 * @return string
 */
export const pluralize = (
    number: number,
    original: string | [string, string],
    hideNumber?: boolean
): string => {
    const [singular, plural] = Array.isArray(original)
        ? original
        : [original, original + 's'];

    const text = number === 1 ? singular : plural;

    return hideNumber ? text : `${number} ${text}`;
};

export const capitalizeFirstLetter = (string: string): string =>
    typeof string === 'string'
        ? string.charAt(0).toUpperCase() + string.slice(1)
        : string;

/**
 * Clean HTML tags from text and reduce to given size
 *
 * @param text string Text to process
 * @param maxChars number Max chars to trim to
 * @return string
 */
export const cleanTagsFromText = (text: string, maxChars: number): string => {
    const cleanText = text.replace(/<[^>]*>?/gm, '');
    return cleanText.substring(0, maxChars);
};

export const decodeEntities = (text: string): string => {
    return text
        .toString()
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#0*39;/g, "'")
        .replace(/&amp;/g, '&');
};

export const superscriptize = (text: string): string => {
    return ['©', '®', '™', '°'].reduce((currentText, symbolIcon) => {
        return currentText.replace(symbolIcon, `<sup>${symbolIcon}</sup>`);
    }, text);
};

export const truncateStringAtWord = (
    str: string,
    limit: number,
    locale = 'en-US'
) => {
    if (str.length <= limit) return str;

    const segmenter = new Intl.Segmenter(locale, { granularity: 'word' });
    let lastWordBreak = null;

    for (let word of segmenter.segment(str)) {
        if (word.index > limit) break;

        if (!word.isWordLike && word.index - 1 !== lastWordBreak) {
            lastWordBreak = word.index;
        }
    }

    return str.slice(0, lastWordBreak ?? limit);
};
